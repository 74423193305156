import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Aduanas, ComponentLifecycle, Rejected, ResponseErrorData } from '../../shared/constants';
import { getCheckinList, listOnfleetAPI, updateCheckinList } from '../aduanaAPI';

export interface Onfleet {
    motorbike_id: string;
    frame_number: string;
    license_plate: string;
    brand: string;
    model: string;
    version: string;
    brandModel: string;
    location: string;
    updated_at: string;
    checkin_list?: OnfleetCheckinListOption[];
    isCheckListCompleted: boolean;
}

export interface Location {
    label: string;
    value: string;
}

export interface OnfleetCheckinListOption {
    label: string;
    value?: any;
    checked?: boolean | null;
    indent?: boolean;
}

export interface onfleetState {
    onfleets: Onfleet[];
    locations: Location[];
    status: string;
    error: string;
}

const initialState: onfleetState = {
    onfleets: [],
    locations: Aduanas,
    status: ComponentLifecycle.IDLE,
    error: '',
};

export const fetchOnfleets = createAsyncThunk<Onfleet[]>('onfleet/fetchOnfleets', async (_, { rejectWithValue }) => {
    try {
        const response = await listOnfleetAPI();

        return response.data.map((onfleet: Onfleet) => ({
            ...onfleet,
            brandModel: `${onfleet.brand} ${onfleet.model}`,
        }));
    } catch (responseError: any) {
        const { name, message } = responseError.response.data as ResponseErrorData;
        return rejectWithValue({
            name,
            message,
        } as Rejected);
    }
});

export const getOnfleetCheckinList = createAsyncThunk(
    'onfleet/checkinOptions',
    async (payload: any, { rejectWithValue }) => {
        try {
            const response = await getCheckinList(payload.motorbike_id);
            return mapChecklistOptions(response.data);
        } catch (responseError: any) {
            const { name, message } = responseError.response.data as ResponseErrorData;
            return rejectWithValue({
                name,
                message,
            } as Rejected);
        }
    }
);

export const mapChecklistOptions = (checkinList: any): OnfleetCheckinListOption[] => [
    { label: 'onfleet_checklist.tire_condition', checked: checkinList.tire_condition },
    { label: 'onfleet_checklist.start_engine_well', checked: checkinList.start_engine_well },
    { label: 'onfleet_checklist.engine_oil_leaks', checked: checkinList.engine_oil_leaks },
    { label: 'onfleet_checklist.suspension_oil', checked: checkinList.suspension_oil },
    { label: 'onfleet_checklist.engine_noise', checked: checkinList.engine_noise },
    { label: 'onfleet_checklist.fairing', checked: checkinList.fairing },
    { label: 'onfleet_checklist.scratch', checked: checkinList.scratch },
    {
        label: 'onfleet_checklist.keys',
        value: isNaN(checkinList.keys) ? 0 : parseInt(checkinList.keys),
    },
    {
        label: 'onfleet_checklist.accessories',
        value: checkinList.accessories === null ? '' : checkinList.accessories,
    },
    { label: 'onfleet_checklist.documentation', checked: checkinList.documentation },
    { label: 'onfleet_checklist.identification', checked: checkinList.identification, indent: true },
    { label: 'onfleet_checklist.traffic_license', checked: checkinList.traffic_license, indent: true },
    {
        label: 'onfleet_checklist.technical_specifications',
        checked: checkinList.technical_specifications,
        indent: true,
    },
    { label: 'onfleet_checklist.contract', checked: checkinList.contract, indent: true },
    { label: 'onfleet_checklist.mandate', checked: checkinList.mandate, indent: true },
    { label: 'onfleet_checklist.user_manual', checked: checkinList.user_manual, indent: true },
    {
        label: 'onfleet_checklist.comments',
        value: checkinList.comments === null ? '' : checkinList.comments,
    },
];

export const updateOnfleetCheckinList = createAsyncThunk(
    'onfleet/updateCheckinOptions',
    async (payload: any, { rejectWithValue }) => {
        try {
            const checkinOptions = {
                ...payload.checkinOptions.reduce(
                    (prev: {}, elem: OnfleetCheckinListOption) => ({
                        ...prev,
                        [elem.label.replace('onfleet_checklist.', '')]:
                            'value' in elem ? `${elem.value}` : elem.checked,
                    }),
                    {}
                ),
            };

            await updateCheckinList(payload.motorbike_id, checkinOptions);
        } catch (responseError: any) {
            const { name, message } = responseError.response.data as ResponseErrorData;
            return rejectWithValue({
                name,
                message,
            } as Rejected);
        }
    }
);

export const hasCheck = (option: OnfleetCheckinListOption): boolean => {
    return !['onfleet_checklist.keys', 'onfleet_checklist.accessories', 'onfleet_checklist.comments'].includes(
        option.label
    );
};

export const isChecklistPending = (checkList: OnfleetCheckinListOption[] | undefined) => {
    return (
        !checkList ||
        checkList?.find((option) => option.label === 'onfleet_checklist.keys')?.value < 1 ||
        checkList?.some((option) => option.checked === null && hasCheck(option))
    );
};

export const onfleetSlice = createSlice({
    name: 'onfleetsState',
    initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(fetchOnfleets.pending, (state, action) => {
                state.status = ComponentLifecycle.LOADING;
                state.onfleets = [];
                state.error = '';
            })
            .addCase(fetchOnfleets.fulfilled, (state, action) => {
                state.status = ComponentLifecycle.SUCCEEDED;
                state.onfleets = action.payload.map((onfleet) => {
                    return {
                        ...onfleet,
                        isCheckListCompleted: !isChecklistPending(mapChecklistOptions(onfleet.checkin_list)),
                    };
                });
            })
            .addCase(fetchOnfleets.rejected, (state, action) => {
                state.status = ComponentLifecycle.FAILED;
                state.error = action.error?.message ?? '';
            })
            .addCase(getOnfleetCheckinList.pending, (state, action) => {
                state.status = ComponentLifecycle.LOADING;
                state.error = '';
            })
            .addCase(getOnfleetCheckinList.fulfilled, (state, action) => {
                state.status = ComponentLifecycle.SUCCEEDED;
                const onfleet = state.onfleets.find((object) => object.motorbike_id === action.meta.arg.motorbike_id);
                if (!!onfleet) {
                    onfleet.checkin_list = action.payload;
                    onfleet.isCheckListCompleted = !isChecklistPending(action.payload);
                }
            })
            .addCase(getOnfleetCheckinList.rejected, (state, action) => {
                state.status = ComponentLifecycle.FAILED;
                state.error = action.error.message ?? '';
            })
            .addCase(updateOnfleetCheckinList.pending, (state, action) => {
                state.status = ComponentLifecycle.LOADING;
                state.error = '';
            })
            .addCase(updateOnfleetCheckinList.fulfilled, (state, action) => {
                state.status = ComponentLifecycle.SUCCEEDED;
                const onfleet = state.onfleets.find((object) => object.motorbike_id === action.meta.arg.motorbike_id);
                if (!!onfleet) {
                    onfleet.checkin_list = action.meta.arg.checkinOptions;
                    onfleet.isCheckListCompleted = !isChecklistPending(action.meta.arg.checkinOptions);
                }
            })
            .addCase(updateOnfleetCheckinList.rejected, (state, action) => {
                state.status = ComponentLifecycle.FAILED;
                state.error = action.error.message ?? '';
            });
    },
});

export default onfleetSlice.reducer;
