import * as i18n from 'i18next';
import XHR from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const options = {
    order: ['navigator'],
};

i18n.use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: options,
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
