import React, { useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { Defleet, fetchDefleets } from './defleetSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DateTimeFormat } from '../../shared/constants';
import MM_Button, { ButtonStyle } from '../../shared/button/Button';

export function DefleetList() {
    const defleetsList = useAppSelector((state) => state.defleetsState.defleets);
    const defleetListStatus = useAppSelector((state) => state.defleetsState.status);
    const locations = useAppSelector((state) => state.defleetsState.locations);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [filters, setFilters] = useState({
        license_plate: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    const [licensePlateFilterValue, setLicensePlateFilterValue] = useState('');

    if (defleetListStatus === 'idle') {
        dispatch(fetchDefleets());
    }

    const getHeader = () => {
        return (
            <div className="flex justify-content-start w-full max-w-23rem">
                <span className="p-input-icon-left w-full">
                    <i className="pi pi-search" />
                    <InputText
                        className="w-full"
                        value={licensePlateFilterValue}
                        onChange={onLicensePlateFilterChanged}
                        placeholder={t('defleet.search')}
                    />
                </span>

                <MM_Button
                    style={ButtonStyle.TEXT}
                    label=""
                    onClick={onLicensePlateClearClicked}
                    icon="pi pi-filter-slash"
                    className={`ml-2 w-min ${filters?.license_plate?.value ? '' : 'opacity-0'}`}
                />
            </div>
        );
    };

    const onLicensePlateFilterChanged = (e: any) => {
        const value = e.target.value;
        const _filters = { ...filters };
        _filters.license_plate.value = value;
        setFilters(_filters);
        setLicensePlateFilterValue(value);
    };

    const onLicensePlateClearClicked = () => {
        const _filters = { ...filters };
        _filters.license_plate.value = null;
        setFilters(_filters);
        setLicensePlateFilterValue('');
    };

    const formatDateTime = (dateTime?: string) => {
        return dateTime ? moment(dateTime).format(DateTimeFormat) : dateTime;
    };

    const mapDefleetDate = (motorbike: Defleet) => {
        return formatDateTime(motorbike.updated_at);
    };

    const locationTemplate = (defleet: Defleet) => {
        const location = locations.find((element) => element.value === defleet.location);
        return <React.Fragment>{location && <span>{t(location.label)}</span>}</React.Fragment>;
    };

    return (
        <div data-testid="defleet">
            <div className="card">
                <h1 className="mm_mainTitle">{t('defleet.title')}</h1>

                <DataTable
                    value={defleetsList}
                    filters={filters}
                    header={getHeader}
                    loading={defleetListStatus === 'loading'}
                    rowHover
                    emptyMessage={t('dataTable.defleetEmptyMessage')}
                >
                    <Column
                        field="license_plate"
                        filterField="license_plate"
                        header={t('dataTable.licensePlate')}
                    ></Column>
                    <Column field="brandModel" header={t('dataTable.model')}></Column>
                    <Column field="location" header={t('dataTable.location')} body={locationTemplate}></Column>
                    <Column
                        field="checkout_date"
                        header={t('dataTable.defleetDate')}
                        dataType="date"
                        body={mapDefleetDate}
                    ></Column>
                </DataTable>
            </div>
        </div>
    );
}
