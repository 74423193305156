import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { Subzone, Zone } from '../../constants';
import ZoneElem from './ZoneElem';
import SubzoneElem from './SubzoneElem';
import ParcelElem from './ParcelElem';
import { useTranslation } from 'react-i18next';
import WarehouseElem from './WarehouseElem';

export default function ZoneSelector({
    subZones,
    onChange,
    title,
}: {
    subZones: Subzone[];
    onChange?: null | ((value?: Subzone) => void);
    title?: null | string;
}) {
    const { t } = useTranslation();

    const [availableWarehouses, setAvailableWarehouses] = useState<string[]>([]);
    const [availableGroups, setAvailableGroups] = useState<Zone[]>([]);
    const [availableZones, setAvailableZones] = useState<Subzone[]>([]);
    const [availableParcels, setAvailableParcels] = useState<Subzone[]>([]);

    const [warehouse, setWarehouse] = useState<string | undefined>(undefined);
    const [group, setGroup] = useState<Zone | undefined>(undefined);
    const [zone, setZone] = useState<Subzone | undefined>(undefined);
    const [parcel, setParcel] = useState<Subzone | undefined>(undefined);

    useEffect(() => {
        const uniqueWarehouses = Array.from(new Set(subZones.map((item) => item.warehouse)));

        setAvailableWarehouses(uniqueWarehouses);
        setWarehouse(uniqueWarehouses.length == 1 ? uniqueWarehouses[0] : undefined);
    }, [subZones]);

    useEffect(() => {
        const resultingWarehouseGroups: Zone[] = [];
        const groupNames: string[] = [];

        for (const { floor, group, code } of subZones.filter((item) => item.warehouse === warehouse)) {
            if (!groupNames.includes(group)) {
                groupNames.push(group);
                resultingWarehouseGroups.push({ zone: group, code: code.replace(/[0-9]/g, ''), floor });
            }
        }

        setAvailableGroups(resultingWarehouseGroups);
        setGroup(resultingWarehouseGroups.length == 1 ? resultingWarehouseGroups[0] : undefined);
    }, [warehouse]);

    useEffect(() => {
        const resultingZones: Subzone[] = [];
        const zoneNames: string[] = [];
        const preFilteredZones: Subzone[] = subZones.filter((item) => item.warehouse === warehouse && item.group === group?.zone);

        preFilteredZones.forEach( item => {
            if (!zoneNames.includes(item.zone)) {
                zoneNames.push(item.zone);
                resultingZones.push(item);
            }
        });

        setAvailableZones(resultingZones);
        setZone(resultingZones.length == 1 ? resultingZones[0] : undefined);
    }, [group]);

    useEffect(() => {
        const availableParcels: Subzone[] = subZones.filter((item) => item.warehouse === warehouse && item.group === group?.zone && item.zone === zone?.zone && item.parcel);
        setAvailableParcels(availableParcels);
        onChange?.(zone);
    }, [zone]);

    useEffect(() => {
        onChange?.(parcel);
    }, [parcel]);

    return (
        <div className="flex flex-wrap justify-content-center gap-2 w-full max-w-23rem surface-200 p-2">
            {title && <h4 className="font-semibold w-full">{title}</h4>}

            {availableWarehouses.length > 1 && (
                <span className="flex flex-column w-full">
                    <Dropdown
                        data-testid="warehouseSelector"
                        className="w-full"
                        scrollHeight="100vh"
                        value={warehouse}
                        options={availableWarehouses}
                        onChange={(e) => setWarehouse(e.value)}
                        placeholder={t('zoneSelector.warehouse')}
                        itemTemplate={(option: string) => <WarehouseElem warehouseCode={option} />}
                        valueTemplate={(option: string, { placeholder }: DropdownProps) =>
                            option ? <WarehouseElem warehouseCode={option} /> : <span>{placeholder}</span>
                        }
                    />
                </span>
            )}

            {!!warehouse && (
                <span className="flex flex-column w-full">
                    <Dropdown
                        data-testid="zoneSelector"
                        className="w-full"
                        scrollHeight="100vh"
                        value={group}
                        options={availableGroups}
                        onChange={(e) => setGroup(e.value)}
                        placeholder={t('zoneSelector.zone')}
                        optionLabel={'code'}
                        itemTemplate={(option: Zone) => <ZoneElem zone={option} />}
                        valueTemplate={(option: Zone, { placeholder }: DropdownProps) =>
                            option ? <ZoneElem zone={option} /> : placeholder
                        }
                    />
                </span>
            )}

            {!!group && availableZones.length > 1 && (
                <span className="flex flex-column w-full">
                    <Dropdown
                        data-testid="subZoneSelector"
                        className="w-full"
                        scrollHeight="100vh"
                        value={zone}
                        options={availableZones}
                        onChange={(e) => setZone(e.value)}
                        placeholder={t('zoneSelector.subzone')}
                        optionLabel={'code'}
                        itemTemplate={(option: Subzone) => <SubzoneElem subzone={option} />}
                        valueTemplate={(option: Subzone, { placeholder }: DropdownProps) =>
                            option ? <SubzoneElem subzone={option} /> : placeholder
                        }
                    />
                </span>
            )}

            { availableParcels.length > 1 && (
                <span className="flex flex-column w-full">
                    <Dropdown
                        data-testid="parcelSelector"
                        className="w-full"
                        scrollHeight="100vh"
                        value={parcel}
                        options={availableParcels}
                        onChange={(e) => setParcel(e.value)}
                        placeholder={t('zoneSelector.parcel')}
                        optionLabel={'code'}
                        itemTemplate={(option: Subzone) => <ParcelElem parcel={option} />}
                        valueTemplate={(option: Subzone, { placeholder }: DropdownProps) =>
                            option ? <ParcelElem parcel={option} /> : placeholder
                        }
                    />
                </span>
            )}
        </div>
    );
}
