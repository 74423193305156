import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ComponentLifecycle, Rejected, ResponseErrorData } from '../../shared/constants';
import { getInformationFromMotorbikeAPI } from '../motorbikeAPI';
import { RootState } from '../../app/store';

export interface versionsOptions {
    id: string;
    name: string;
    cubic_capacity: number;
    year: number;
}

export interface modelsOptions {
    id: string;
    name: string;
    versions: versionsOptions[];
}

export interface brandsOptions {
    id: string;
    name: string;
    models: modelsOptions[];
}

export interface newMotoState {
    brands: brandsOptions[];
    status: string;
    error: string;
}

export const fetchMotorbikeInformation = createAsyncThunk<brandsOptions[]>(
    'newMoto/fetchMotorbikeInformation',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getInformationFromMotorbikeAPI();

            return response.data.map((motorbikeInfo: brandsOptions) => ({
                id: motorbikeInfo.id,
                name: motorbikeInfo.name,
                models: motorbikeInfo.models.map((modelsInformation: modelsOptions) => ({
                    id: modelsInformation.id,
                    name: modelsInformation.name,
                    versions: modelsInformation.versions.map((versionInformation: versionsOptions) => ({
                        id: versionInformation.id,
                        name: versionInformation.name,
                        cubic_capacity: versionInformation.cubic_capacity,
                        year: versionInformation.year,
                    })),
                })),
            }));
        } catch (responseError: any) {
            const { name, message } = responseError.response.data as ResponseErrorData;
            return rejectWithValue({
                name,
                message,
            } as Rejected);
        }
    }
);

const initialState: newMotoState = {
    brands: [],
    status: ComponentLifecycle.IDLE,
    error: '',
};

export const newMotoSlice = createSlice({
    name: 'newMotoState',
    initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(fetchMotorbikeInformation.pending, (state, action) => {
                state.status = ComponentLifecycle.LOADING;
                state.error = '';
            })
            .addCase(fetchMotorbikeInformation.fulfilled, (state, action) => {
                state.status = ComponentLifecycle.SUCCEEDED;
                state.brands = action.payload;
            })
            .addCase(fetchMotorbikeInformation.rejected, (state, action) => {
                state.status = ComponentLifecycle.FAILED;
                state.error = action.error?.message ?? '';
            });
    },
});

export const motorbikeBrandsInformation = (state: RootState) => state.newMotoState.brands;
export const motorbikesInformationLifecycle = (state: RootState) => state.newMotoState.status;
export const motorbikesInformationError = (state: RootState) => state.newMotoState.error;

export default newMotoSlice.reducer;
