import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import onfleetReducer from '../features/onfleet/onfleetSlice';
import defleetReducer from '../features/defleet/defleetSlice';
import qrReducer from '../features/qrGenerator/qrSlice';
import newMotoReducer from '../features/newMoto/newMotoSlice';
import { dataSlice } from '../features/qrGenerator/dataSlice';

export const store = configureStore({
    reducer: {
        onfleetsState: onfleetReducer,
        defleetsState: defleetReducer,
        qrState: qrReducer,
        newMotoState: newMotoReducer,
        data: dataSlice.reducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
