import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export function AddToken() {
    const location = useLocation();
    const token = new URLSearchParams(location.hash.substring(1)).get('access_token');

    localStorage.setItem('token', typeof token === 'string' ? token : '');

    return <Navigate to="/" />;
}
