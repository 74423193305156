import axios from 'axios';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import { addLocale, FilterMatchMode, locale } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar, CalendarChangeParams } from 'primereact/calendar';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputNumber, InputNumberChangeParams } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton, SelectButtonChangeParams } from 'primereact/selectbutton';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import MM_Button, { ButtonStyle } from '../../shared/button/Button';
import { ComponentLifecycle, DateTimeFormat } from '../../shared/constants';
import Qr from '../qrGenerator/Qr';
import { MotoInfo } from '../qrGenerator/qrSlice';
import './onfleet.scss';
import {
    fetchOnfleets,
    getOnfleetCheckinList,
    hasCheck,
    isChecklistPending,
    Location,
    Onfleet,
    OnfleetCheckinListOption,
    updateOnfleetCheckinList,
} from './onfleetSlice';

export function OnfleetList() {
    const onfleetsList = useAppSelector((state) => state.onfleetsState.onfleets);
    const locations = useAppSelector((state) => state.onfleetsState.locations);
    const onfleetListStatus = useAppSelector((state) => state.onfleetsState.status);
    const dispatch = useAppDispatch();
    const [filters, setFilters] = useState({});
    const [currentQR, setCurrentQR] = useState<MotoInfo | null>(null);
    const [qrDownloaded, setQRDownloaded] = useState(false);
    const [commentsDialog, setCommentsDialog] = useState(false);
    const [saveData, setSaveData] = useState(onfleetsList[0]);
    const { t, i18n } = useTranslation();
    const options = [
        { label: t('onfleet_checklist.no'), value: false },
        { label: t('onfleet_checklist.yes'), value: true },
    ];

    useEffect(() => {
        initFilters();
        setLanguageCalendar();
    }, [dispatch]);

    function setLanguageCalendar() {
        if (i18n) {
            axios('./locales/' + i18n.resolvedLanguage + '/calendar.json')
                .then((res: any) => res.data)
                .then((data: any) => {
                    addLocale(i18n.resolvedLanguage, data);
                    locale(i18n.resolvedLanguage);
                });
        }
    }

    const initFilters = () => {
        setFilters({
            license_plate: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            brandModel: { value: null, matchMode: FilterMatchMode.CONTAINS },
            location: { value: null, matchMode: FilterMatchMode.EQUALS },
            updated_at: { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
    };

    if (onfleetListStatus === ComponentLifecycle.IDLE) {
        dispatch(fetchOnfleets());
    }

    const formatDateTime = (dateTime?: string) => {
        return dateTime ? moment(dateTime).format(DateTimeFormat) : dateTime;
    };

    const mapOnfleetDate = (motorbike: Onfleet) => {
        return formatDateTime(motorbike.updated_at);
    };

    const mapQR = (motorbike: Onfleet) => {
        return (
            <MM_Button
                style={ButtonStyle.TEXT}
                label=""
                icon="pi pi-qrcode text-color"
                className="w-min"
                onClick={() => {
                    const motoInfo: MotoInfo = {
                        ...motorbike,
                        kms: -1,
                        year: -1,
                        model_id: 'fake_id',
                    };
                    setCurrentQR(motoInfo);
                }}
            />
        );
    };

    const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return (
            <Calendar
                value={options.value}
                onChange={(calendar: CalendarChangeParams) => {
                    const date = calendar.value as Date;
                    if (date) {
                        options.filterApplyCallback(moment(date).format('YYYY-MM-DD'));
                    }
                }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
                mask="99/99/9999"
                readOnlyInput
            />
        );
    };

    const licenseTemplate = (onfleet: Onfleet) => {
        return (
            <React.Fragment>
                <span>{onfleet.license_plate}</span>
            </React.Fragment>
        );
    };

    const checkinListTemplate = (onfleet: Onfleet) => {
        return (
            <MM_Button
                style={ButtonStyle.TEXT}
                label=""
                className="w-min"
                icon={`pi ${
                    onfleet.isCheckListCompleted ? 'pi-check-circle text-green-500' : 'pi-times-circle text-red-500'
                }`}
                onClick={() => showCommentsDialog(onfleet)}
            />
        );
    };

    const locationTemplate = (onfleet: Onfleet) => {
        const location = locations.find((element) => element.value === onfleet.location);
        return <React.Fragment>{location && <span>{t(location.label)}</span>}</React.Fragment>;
    };

    const locationFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return (
            <Dropdown
                value={options.value}
                options={locations}
                onChange={(location: DropdownChangeParams) => options.filterApplyCallback(location.value)}
                className="p-column-filter"
                scrollHeight="100vh"
                valueTemplate={(option: Location) =>
                    option ? <span>{t(option.label)}</span> : <span className="opacity-0">-</span>
                }
                itemTemplate={(option: Location) => <span>{t(option.label)}</span>}
            />
        );
    };

    const showCommentsDialog = (motorbike: Onfleet) => {
        dispatch(getOnfleetCheckinList({ motorbike_id: motorbike.motorbike_id })).then((data) => {
            let checkinList: OnfleetCheckinListOption[] = data.payload as OnfleetCheckinListOption[];
            if (checkinList.every((option) => !option.checked)) {
                checkinList = checkinList.map((optionList) => {
                    return { ...optionList, checked: null };
                });
            }
            setSaveData({ ...motorbike, checkin_list: checkinList });
            setCommentsDialog(true);
        });
    };

    const hideCommentsDialog = () => {
        setCommentsDialog(false);
    };

    const updateCheckinOptions = () => {
        setCommentsDialog(false);
        dispatch(
            updateOnfleetCheckinList({ motorbike_id: saveData.motorbike_id, checkinOptions: saveData.checkin_list })
        );
    };

    const commentsDialogFooter = (
        <div className="w-full flex align-items-center justify-content-end pt-3">
            <MM_Button
                style={ButtonStyle.TEXT}
                className="w-min px-4"
                label={t('dialog.cancel')}
                onClick={hideCommentsDialog}
            />

            <MM_Button
                className="w-min px-4"
                label={t('dialog.save')}
                icon="pi pi-check"
                disabled={isChecklistPending(saveData?.checkin_list)}
                onClick={updateCheckinOptions}
            />
        </div>
    );

    const onOptionChange = (event: SelectButtonChangeParams) => {
        if (saveData.checkin_list) {
            const optionList = saveData.checkin_list.map((option) => {
                if (option.label === event.target.id) {
                    return { ...option, checked: event.value };
                } else {
                    return option;
                }
            });
            setSaveData({ ...saveData, checkin_list: optionList });
        }
    };

    const setValueOption = (option: OnfleetCheckinListOption, value: string | number | null) => {
        if (saveData.checkin_list) {
            let options: OnfleetCheckinListOption[] = [];
            options = saveData.checkin_list.map((checkbox) => {
                let checkOption = checkbox;
                if (checkbox.label === option.label) {
                    checkOption = { ...checkbox, value: value };
                }
                return checkOption;
            });
            if (saveData.checkin_list) {
                setSaveData({ ...saveData, checkin_list: options });
            }
        }
    };

    function renderChecklistContent() {
        return saveData?.checkin_list?.map((option: OnfleetCheckinListOption, index: number) => {
            return (
                <div key={saveData?.license_plate + index} className="mb-3">
                    <div className={`field-checkbox ${option?.indent ? 'ml-4' : ''}`}>
                        {hasCheck(option) && (
                            <SelectButton
                                id={option.label}
                                value={option.checked}
                                options={options}
                                onChange={onOptionChange}
                            />
                        )}
                        <label htmlFor={option.label}>{t(option.label)}</label>
                    </div>
                    {'value' in option && (
                        <div>
                            {option.label === 'onfleet_checklist.accessories' && (
                                <InputText
                                    id={option.label}
                                    name={option.label}
                                    itemID={option.label}
                                    value={option.value}
                                    onChange={(e) => setValueOption(option, e.target.value)}
                                />
                            )}
                            {option.label === 'onfleet_checklist.keys' && (
                                <InputNumber
                                    inputId="integeronly"
                                    value={option.value}
                                    onValueChange={(e: InputNumberChangeParams) => setValueOption(option, e.value)}
                                />
                            )}
                            {option.label === 'onfleet_checklist.comments' && (
                                <InputTextarea
                                    id="comments"
                                    name="comments"
                                    itemID="comments"
                                    value={option.value}
                                    onChange={(e) => setValueOption(option, e.target.value)}
                                    rows={5}
                                    cols={60}
                                    autoResize
                                />
                            )}
                        </div>
                    )}
                </div>
            );
        });
    }

    async function downloadQrAsPdf(motoInfo: MotoInfo) {
        const qrDOM = document.getElementById('qr-gen') as HTMLElement;
        var doc = new jsPDF();
        const pdfWidth = doc.internal.pageSize.getWidth();
        const name = `${motoInfo?.license_plate ? motoInfo?.license_plate + '_' : ''}${motoInfo?.frame_number}.pdf`;

        doc.html(qrDOM, {
            callback: (doc) => doc.save(name),
            x: 0,
            y: 0,
            width: pdfWidth,
            windowWidth: qrDOM.getBoundingClientRect().width,
        });
    }

    useEffect(() => {
        const download = async () => {
            if (!qrDownloaded && currentQR?.motorbike_id) {
                await downloadQrAsPdf(currentQR);
                setQRDownloaded(true);
            } else if (currentQR?.motorbike_id) {
                setCurrentQR(null);
                setQRDownloaded(false);
            }
        };

        download();
    });

    return (
        <div data-testid="onfleet">
            <div className="card">
                <h1 className="mm_mainTitle">{t('onfleet')}</h1>

                <DataTable
                    data-testid="onfleet-table"
                    value={onfleetsList}
                    filters={filters}
                    filterDisplay="row"
                    loading={onfleetListStatus === 'loading'}
                    rowHover
                    size="small"
                    emptyMessage={t('dataTable.onfleetEmptyMessage')}
                >
                    <Column
                        field="license_plate"
                        filterField="license_plate"
                        header={t('dataTable.licensePlate')}
                        filter
                        showFilterMenu={false}
                        body={licenseTemplate}
                    ></Column>
                    <Column
                        field="brandModel"
                        filterField="brandModel"
                        header={t('dataTable.model')}
                        filter
                        showFilterMenu={false}
                    ></Column>
                    <Column
                        field="location"
                        filterField="location"
                        header={t('dataTable.location')}
                        filter
                        showFilterMenu={false}
                        body={locationTemplate}
                        filterElement={locationFilterTemplate}
                    ></Column>
                    <Column
                        field="updated_at"
                        filterField="updated_at"
                        header={t('dataTable.onfleetDate')}
                        dataType="date"
                        body={mapOnfleetDate}
                        filter
                        showFilterMenu={false}
                        filterElement={dateFilterTemplate}
                    ></Column>
                    <Column body={checkinListTemplate}></Column>
                    <Column body={mapQR}></Column>
                </DataTable>
            </div>
            <Dialog
                id={saveData?.license_plate}
                modal
                visible={commentsDialog}
                header={t('dialog.title')}
                footer={commentsDialogFooter}
                onHide={hideCommentsDialog}
                draggable={false}
                closable={false}
            >
                <div className="pb-3">{t('dialog.subtitle')}</div>
                {renderChecklistContent()}
            </Dialog>

            <Qr currentQR={currentQR} />
        </div>
    );
}
