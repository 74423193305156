import { jsPDF } from 'jspdf';
import { InputText } from 'primereact/inputtext';
import React, { FormEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ComponentLifecycle } from '../../shared/constants';
import { addMotoToFreeFlow, MotoInfo } from '../qrGenerator/qrSlice';
import Qr from '../qrGenerator/Qr';
import { useTranslation } from 'react-i18next';
import { InputNumber } from 'primereact/inputnumber';
import MM_Button from '../../shared/button/Button';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import {
    brandsOptions,
    fetchMotorbikeInformation,
    modelsOptions,
    motorbikeBrandsInformation,
    versionsOptions,
} from './newMotoSlice';

interface NewMotoInfo {
    frame_number: string | null;
    license_plate: string | null;
    brand: string | null;
    model: string | null;
    kms: number | null;
    version: string | null;
    year: number | null;
    model_id: string | null;
}
interface NewMotoInfoWithoutVersion {
    frame_number: string | null;
    license_plate: string | null;
    brand: string | null;
    model: string | null;
    kms: number | null;
    year: number | null;
    model_id: string | null;
}

const emptyNewMoto = {
    license_plate: null,
    frame_number: null,
    brand: null,
    model: null,
    version: null,
    kms: null,
    year: null,
    model_id: null,
};

export default function NewMoto() {
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();

    const { status, error, httpStatus } = useAppSelector((state) => state.qrState);
    const motorbikeBrands = useAppSelector(motorbikeBrandsInformation);

    const [downloading, setDownloading] = useState(false);
    const [currentQR, setCurrentQR] = useState<MotoInfo | null>(null);
    const [lastMoto, setLastMoto] = useState<MotoInfo | null>(null);
    const [newMoto, setNewMoto] = useState<NewMotoInfo>(emptyNewMoto);

    async function downloadQrAsPdf(motoInfo: MotoInfo) {
        const qrDOM = document.getElementById('qr-gen') as HTMLElement;
        var doc = new jsPDF();
        const pdfWidth = doc.internal.pageSize.getWidth();
        const name = `${motoInfo?.license_plate ? motoInfo?.license_plate + '_' : ''}${motoInfo?.frame_number}.pdf`;

        doc.html(qrDOM, {
            callback: (doc) => doc.save(name),
            x: 0,
            y: 0,
            width: pdfWidth,
            windowWidth: qrDOM.getBoundingClientRect().width,
        });

        setDownloading(false);
        setCurrentQR(null);
    }

    useEffect(() => {
        if (currentQR) downloadQrAsPdf(currentQR);
    }, [currentQR]);

    useEffect(() => {
        dispatch(fetchMotorbikeInformation());
    }, []);

    const onDownloadQR = () => {
        if (!lastMoto) return;

        setDownloading(true);
        setCurrentQR(lastMoto);
    };

    const clearDialop = () => {
        setNewMoto(emptyNewMoto);
    };

    const getBrandsArray = () => {
        let brands: any[] = [];
        motorbikeBrands.map((info: brandsOptions) => {
            brands.push({ name: info.name, value: info.name });
        });

        return brands;
    };

    const getModelsArray = () => {
        let models: any[] = [];

        motorbikeBrands.map((info: brandsOptions) => {
            if (info.name === newMoto.brand) {
                info.models.map((infoModels: modelsOptions) => {
                    models.push({ name: infoModels.name, value: infoModels.name });
                });
            }
        });

        return models;
    };

    const getVersionsArray = () => {
        let versions: any[] = [];

        motorbikeBrands.map((info: brandsOptions) => {
            if (info.name === newMoto.brand) {
                info.models.map((infoModels: modelsOptions) => {
                    if (infoModels.name === newMoto.model) {
                        infoModels.versions.map((infoVersions: versionsOptions) => {
                            let name =
                                infoVersions.name +
                                ' / ' +
                                infoVersions.cubic_capacity +
                                'cc (' +
                                infoVersions.year +
                                ')';
                            versions.push({
                                name: name,
                                value: infoVersions.id,
                                id: infoVersions.id,
                            });
                        });
                    }
                });
            }
        });

        return versions;
    };

    const onDialogEnter = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let versionName = '';

        motorbikeBrands.map((info: brandsOptions) => {
            if (info.name === newMoto.brand) {
                info.models.map((infoModels: modelsOptions) => {
                    if (infoModels.name === newMoto.model) {
                        infoModels.versions.map((infoVersions: versionsOptions) => {
                            if (infoVersions.id === newMoto.version) {
                                versionName = infoVersions.name;
                            }
                        });
                    }
                });
            }
        });

        const newMotoWithoutVersion: NewMotoInfoWithoutVersion = {
            brand: newMoto.brand,
            kms: newMoto.kms,
            license_plate: newMoto.license_plate,
            model: newMoto.model + ' ' + versionName,
            year: newMoto.year,
            frame_number: newMoto.frame_number,
            model_id: newMoto.version,
        };

        const newFreeFlowMoto = newMotoWithoutVersion as MotoInfo;
        if (!newFreeFlowMoto.license_plate) delete newFreeFlowMoto.license_plate;

        const response = await dispatch(addMotoToFreeFlow(newMotoWithoutVersion as MotoInfo));
        if ('error' in response) {
            setLastMoto(null);
            return;
        }

        setLastMoto(response.payload);
        clearDialop();
    };

    return (
        <div className="h-100 flex flex-column align-items-center justify-content-center">
            <h1 className="mm_mainTitle w-full max-w-23rem">{t('menu.newMoto')}</h1>

            <h3 className="font-semibold w-full max-w-23rem">{t('newMoto.title')}:</h3>

            <form
                onSubmit={onDialogEnter}
                className="w-full my-3 flex flex-column align-items-center justify-content-center"
            >
                <div className="w-full max-w-23rem flex flex-column justify-content-center">
                    <label className="mb-1">{t('dataTable.licensePlate')}</label>
                    <InputText
                        className="uppercase mb-3"
                        value={newMoto?.license_plate || ''}
                        onChange={(e) => setNewMoto({ ...newMoto, license_plate: e.target.value.toUpperCase() })}
                    />

                    <label className="mb-1">{t('dataTable.frameNumber')}</label>
                    <InputText
                        className="uppercase mb-3"
                        value={newMoto?.frame_number || ''}
                        onChange={(e) => setNewMoto({ ...newMoto, frame_number: e.target.value.toUpperCase() })}
                    />

                    <label className="mb-1">{t('dataTable.brand')}</label>
                    <Dropdown
                        className="mb-3"
                        filter
                        value={newMoto?.brand || null}
                        options={getBrandsArray()}
                        optionLabel="name"
                        onChange={(e) => setNewMoto({ ...newMoto, brand: e.value })}
                    />

                    <label className="mb-1">{t('dataTable.model')}</label>
                    <Dropdown
                        className="mb-3"
                        filter
                        value={newMoto?.model || null}
                        options={getModelsArray()}
                        optionLabel="name"
                        onChange={(e) => setNewMoto({ ...newMoto, model: e.value })}
                    />

                    <label className="mb-1">{t('dataTable.version')}</label>
                    <Dropdown
                        className="mb-3"
                        filter
                        value={newMoto?.version || null}
                        options={getVersionsArray()}
                        optionLabel="name"
                        onChange={(e) => {
                            setNewMoto({ ...newMoto, version: e.value, model_id: e.target.id });
                        }}
                    />

                    <label className="mb-1">{t('dataTable.km')}</label>
                    <InputNumber
                        className="mb-3"
                        locale={i18n.language}
                        value={newMoto?.kms || null}
                        onChange={(e) => setNewMoto({ ...newMoto, kms: e.value })}
                    />

                    <label className="mb-1">{t('dataTable.year')}</label>
                    <InputNumber
                        className="mb-3"
                        locale={i18n.language}
                        useGrouping={false}
                        value={newMoto?.year || null}
                        onChange={(e) => setNewMoto({ ...newMoto, year: e.value })}
                    />
                </div>

                <div className="flex align-items-center justify-content-center w-full max-w-23rem">
                    <MM_Button
                        label={t('dialog.save')}
                        icon="pi pi-check"
                        disabled={
                            !newMoto.frame_number || !newMoto.brand || !newMoto.model || !newMoto.kms || !newMoto.year
                        }
                        loading={status === ComponentLifecycle.LOADING || downloading}
                    />
                </div>
            </form>

            {lastMoto && (
                <div className="w-full max-w-23rem flex align-items-center justify-content-center mb-2">
                    <span className="text-lg font-semibold mr-2">{lastMoto.license_plate}</span>
                    <span className="text-lg mr-2">{lastMoto.brand}</span>
                    <span className="text-lg mr-2">{lastMoto.model}</span>
                    <MM_Button
                        label=""
                        icon="pi pi-qrcode"
                        className="ml-3 w-min"
                        onClick={onDownloadQR}
                        loading={downloading}
                    />
                </div>
            )}

            <div className="flex align-items-center justify-content-center w-full max-w-23rem">
                {status === ComponentLifecycle.FAILED &&
                    (error === 'addMotoToFreeFlowError' || error === 'checkMotoDoesNotExistError') && (
                        <div
                            className={
                                'w-full my-3 flex align-items-center justify-content-center bg-red-600 border-round-md p-3'
                            }
                        >
                            <i className="pi pi-times-circle text-white mr-2"></i>
                            <p className="text-white m-0">
                                {httpStatus === 409 ? t('qrGenerator.error.exists') : t('qrGenerator.error.default')}
                            </p>
                        </div>
                    )}

                {status === ComponentLifecycle.SUCCEEDED && (
                    <div
                        className={
                            'w-full my-3 flex align-items-center justify-content-center bg-green-600 border-round-md p-3'
                        }
                    >
                        <i className="pi pi-check-circle text-white mr-2"></i>
                        <p className="text-white m-0">{t('newMoto.success')}</p>
                    </div>
                )}
            </div>

            <Qr currentQR={currentQR} />
        </div>
    );
}
