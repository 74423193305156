import styles from './Button.module.scss';
import React from 'react';
import { RenderIf } from '../components/RenderIf';

export enum ButtonStyle {
    FULL = 'full',
    EMPTY = 'empty',
    EMTY_INVERTED = 'empty_inverted',
    TEXT = 'text',
}
export default function MM_Button({
    label,
    disabled,
    loading,
    onClick,
    className,
    style,
    icon,
}: {
    label: string;
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
    className?: string;
    style?: ButtonStyle;
    icon?: string;
}) {
    const styleClass = styles[`mm_button_${!style ? ButtonStyle.FULL : style}`];

    return (
        <button
            className={`${styleClass} ${className} ${disabled ? styles.disabled : ''} ${loading ? styles.loading : ''}`}
            data-testid="mm_button"
            type={onClick ? 'button' : 'submit'}
            disabled={disabled || loading}
            onClick={onClick ? onClick : () => null}
        >
            <RenderIf isTrue={loading}>
                <i className={`pi pi-spin pi-spinner text-xl mx-2`}></i>
            </RenderIf>

            <RenderIf isTrue={!loading && icon}>
                <i className={`pi ${icon} text-xl mx-2`}></i>
            </RenderIf>

            <RenderIf isTrue={!loading}>
                <p>{label}</p>
            </RenderIf>
        </button>
    );
}
