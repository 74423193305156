import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import './App.scss';
import { DefleetList } from './features/defleet/DefleetList';
import NewMoto from './features/newMoto/NewMoto';
import { OnfleetList } from './features/onfleet/OnfleetList';
import QrGenerator from './features/qrGenerator/QrGenerator';
import { Menu } from './features/structure/Menu';
import { AddToken } from './features/tokens/AddToken';
import { RedirectLogInPage, RemoveToken } from './features/tokens/RemoveToken';
import ZoneQRGenerator from './features/zoneQRGenerator/zoneQRGenerator';
import SetPosition from './features/setPosition/SetPosition';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RoutePaths } from './shared/constants';

// @ts-ignore
const PrivateOutlet: React.FC = function privateOutlet({ children }) {
    const token = localStorage.getItem('token');

    return process.env.NODE_ENV === 'production' && (token === null || token === undefined || token === '') ? (
        <RedirectLogInPage /> // Go back to log-in
    ) : (
        <>
            {children}
            <Outlet />
        </>
    );
};

function App() {
    return (
        <div className="page">
            <Menu />
            <div className="content-wrapper">
                <BrowserRouter>
                    <RoutesWrapper />
                </BrowserRouter>
            </div>
        </div>
    );
}

export function RoutesWrapper() {
    return (
        <>
            <Routes>
                <Route path={RoutePaths.TOKEN} element={<AddToken />} />
                <Route path={RoutePaths.LOGOUT} element={<RemoveToken />} />
                <Route path={RoutePaths.HOME} element={<OnfleetList />} />
                <Route path={RoutePaths.ONFLEET} element={<OnfleetList />} />
                <Route path={RoutePaths.DEFLEET} element={<DefleetList />} />
                <Route path={RoutePaths.QR} element={<QrGenerator />} />
                <Route path={RoutePaths.NEW_MOTO} element={<NewMoto />} />
                <Route path={RoutePaths.ZONE_QR} element={<ZoneQRGenerator />} />
                <Route path={RoutePaths.POSITION} element={<SetPosition />} />
            </Routes>
            <ToastContainer position="bottom-center" theme="colored" pauseOnFocusLoss newestOnTop draggable />
        </>
    );
}

export default App;
