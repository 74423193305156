import React, { useEffect, useRef, useState } from 'react';
import QrScanner, { CanResetLastScan } from '../qrScanner/QrScanner';
import { MotoQR } from '../qrScanner/QrScanner.models';
import ZoneSelector from '../../shared/components/zoneselector/ZoneSelector';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import useGetData from '../../shared/hooks/useGetData';
import { Subzone } from '../../shared/constants';
import { setPosition } from '../qrGenerator/qrSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function SetPosition() {
    const SAME_QR_SCAN_COOLDOWN_WHEN_ERROR_IN_SECONDS = 5;

    const dispatch = useAppDispatch();
    useGetData();
    const { t } = useTranslation();

    const { warehouse } = useAppSelector((state) => state.data);
    const qrError = useAppSelector((state) => state.qrState.error);
    const [subzone, setSubzone] = useState<Subzone>();

    const qrScannerRef = useRef<CanResetLastScan>(null);
    const sameQRScanCooldown = useRef<NodeJS.Timeout>();

    useEffect(() => {
        qrScannerRef.current?.resetLastScan();
    }, [subzone]);

    useEffect(() => {
        if (qrError === 'setPositionError') {
            startSameQRScanCooldown();
        }
    }, [qrError]);

    const handleQRResult = async (result: string) => {
        try {
            const scannedQR: MotoQR = JSON.parse(result);
            if (!!subzone) {
                dispatch(
                    setPosition({
                        motorbike_id: scannedQR.id,
                        movement: {
                            warehouse: subzone.warehouse,
                            floor: subzone.floor,
                            zone: subzone.zone,
                            parcel: subzone.parcel,
                        },
                    })
                );
            }
            return true;
        } catch (error) {
            startSameQRScanCooldown();
            toast.error(t('error.moveMotorbike'));
            return false;
        }
    };

    const startSameQRScanCooldown = () => {
        sameQRScanCooldown.current = setTimeout(
            () => qrScannerRef.current?.resetLastScan(),
            SAME_QR_SCAN_COOLDOWN_WHEN_ERROR_IN_SECONDS * 1000
        );
    };

    return (
        <div className="h-full flex flex-column align-items-center" data-testid="setposition">
            <h1 className="mm_mainTitle w-full max-w-23rem">{t('menu.position')}</h1>

            <ZoneSelector
                subZones={warehouse.zones}
                onChange={(subzone) => setSubzone(subzone)}
                title={t('setPosition.motosPosition')}
            />

            {subzone && (
                <div className="relative w-full max-w-23rem max-h-23rem flex flex-column align-items-center justify-content-center mt-3">
                    <QrScanner ref={qrScannerRef} handleQRResult={handleQRResult} />
                </div>
            )}
        </div>
    );
}
