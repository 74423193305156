import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ComponentLifecycle, Rejected, ResponseErrorData, Subzone } from '../../shared/constants';
import { getWarehouseList } from '../operationsAPI';

interface WarehouseState {
    zones: Subzone[];
    status: ComponentLifecycle;
    error: ResponseErrorData | null;
}

export interface DataState {
    warehouse: WarehouseState;
}

const initialState: DataState = {
    warehouse: {
        zones: [],
        status: ComponentLifecycle.IDLE,
        error: null,
    },
};

export const doGetWarehouseList = createAsyncThunk(
    'warehouse/getWarehouseList',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getWarehouseList();
            return response.data as Subzone[];
        } catch (responseError: any) {
            const { name, message } = responseError.response.data as ResponseErrorData;
            return rejectWithValue({
                name,
                message,
            } as Rejected);
        }
    }
);

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        clearWarehouse: (state) => {
            state.warehouse.zones = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(doGetWarehouseList.pending, (state) => {
                state.warehouse.status = ComponentLifecycle.LOADING;
                state.warehouse.error = null;
            })
            .addCase(doGetWarehouseList.fulfilled, (state, action) => {
                state.warehouse.zones = action.payload;
                state.warehouse.status = ComponentLifecycle.SUCCEEDED;
            })
            .addCase(doGetWarehouseList.rejected, (state, action) => {
                state.warehouse.status = ComponentLifecycle.FAILED;
                state.warehouse.error = action.error ? (action.payload as ResponseErrorData) : null;
            });
    },
});

const actions = { ...dataSlice.actions, doGetWarehouseList };
export default actions;
