import React from 'react';
import { Subzone } from '../../constants';

export default function ParcelElem({ parcel }: { parcel: Subzone }) {

    return (
        <div className="w-full flex align-items-center">
            <span className="flex-grow-1 opacity-80 text-sm">{parcel.parcel}</span>
        </div>
    );
}
