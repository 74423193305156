import React from 'react';
import { MotoInfo } from './qrSlice';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

interface Props {
    currentQR: MotoInfo | null;
}

export default function Qr({ currentQR }: Props) {
    const { t } = useTranslation();

    return (
        currentQR && (
            <div
                id="qr-gen"
                className={`relative bg-white flex align-items-center justify-content-center w-min mt-1 ml-1 ${
                    currentQR.motorbike_id ? '' : 'opacity-0'
                }`}
            >
                {currentQR.motorbike_id && (
                    <QRCode
                        value={JSON.stringify({ id: currentQR.motorbike_id })}
                        size={450}
                        level={'H'}
                        includeMargin={true}
                    />
                )}

                <div className="w-18rem flex flex-column align-items-center justify-content-center">
                    {currentQR?.license_plate && (
                        <div className="mb-3 w-full">
                            <p className="m-0 text-center text-400 w-full">{t('dataTable.licensePlate')}</p>
                            <p className="m-0 text-center text-6xl font-semibold w-full">{currentQR.license_plate}</p>
                        </div>
                    )}

                    {currentQR.frame_number && (
                        <div className="mb-3 w-full">
                            <p className="m-0 text-center text-400 w-full">{t('dataTable.frameNumber')}</p>
                            <p className="m-0 text-center text-2xl font-semibold w-full">{currentQR.frame_number}</p>
                        </div>
                    )}

                    {currentQR.brand && currentQR.model && (
                        <div className="mb-3 w-full">
                            <p className="m-0 text-center text-400 w-full">{t('dataTable.model')}</p>
                            <div className="w-full flex align-items-center justify-content-center">
                                {`${currentQR.brand} ${currentQR.model}`.split(' ').map((elem, i) => (
                                    <p
                                        key={i}
                                        className={`m-0 text-center text-2xl font-semibold ${i !== 0 ? 'ml-3' : ''}`}
                                    >
                                        {elem}
                                    </p>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    );
}
