import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ComponentLifecycle } from '../constants';
import { doGetWarehouseList } from '../../features/qrGenerator/dataSlice';
import { useMountEffect } from 'primereact/hooks';

export default function useGetData() {
    const dispatch = useAppDispatch();
    const { warehouse } = useAppSelector((state) => state.data);

    useMountEffect(() => {
        if (warehouse.status !== ComponentLifecycle.SUCCEEDED) {
            dispatch(doGetWarehouseList());
        }
    });
}
