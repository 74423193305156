import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RenderIf } from '../../shared/components/RenderIf';
import { RoutePaths } from '../../shared/constants';
import useResize from '../../shared/hooks/useResize';
import './Menu.scss';

const WIDTH = () => window.innerWidth;

export function Menu() {
    const { t } = useTranslation();

    const path = window.location.pathname;

    const items = [
        {
            label: t('menu.onfleet'),
            icon: 'pi pi-arrow-right',
            url: RoutePaths.ONFLEET,
            className: path === RoutePaths.ONFLEET || path === RoutePaths.HOME ? 'selectedMenuItem' : '',
            expanded: true,
        },
        {
            label: t('menu.defleet'),
            icon: 'pi pi-arrow-left',
            url: RoutePaths.DEFLEET,
            className: path === RoutePaths.DEFLEET ? 'selectedMenuItem' : '',
            expanded: true,
        },
        {
            label: t('menu.newMoto'),
            icon: 'pi pi-plus',
            url: RoutePaths.NEW_MOTO,
            className: path === RoutePaths.NEW_MOTO ? 'selectedMenuItem' : '',
        },
        {
            label: t('menu.position'),
            icon: 'pi pi-map-marker',
            url: RoutePaths.POSITION,
            className: path === RoutePaths.POSITION ? 'selectedMenuItem' : '',
        },
        {
            label: t('menu.qrGenerator'),
            icon: 'pi pi-qrcode',
            url: RoutePaths.QR,
            className: path === RoutePaths.QR ? 'selectedMenuItem' : '',
        },
        {
            label: t('menu.zoneQr'),
            icon: 'pi pi-tags',
            url: RoutePaths.ZONE_QR,
            className: path === RoutePaths.ZONE_QR ? 'selectedMenuItem' : '',
        },
    ];

    const shouldCollapse = () => WIDTH() < 960;
    const [collapsed, setCollapsed] = useState(shouldCollapse());

    useResize(() => {
        setCollapsed(shouldCollapse());
    });

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div className="mm_menu">
            <RenderIf isTrue={collapsed}>
                {items.slice(0, 2).map(({ label, icon, url, className }, i) => (
                    <a href={url} key={i} className={`mm_menuItem ${className}`}>
                        <i className={`pi ${icon} text-xl mr-2`}></i>
                        {label}
                    </a>
                ))}

                <div className="flex-grow-1"></div>

                <i
                    onClick={() => setMenuOpen((prev) => !prev)}
                    className={`pi ${menuOpen ? 'pi-times' : 'pi-bars'} mm_more cursor-pointer`}
                ></i>

                <RenderIf isTrue={menuOpen}>
                    <div className="mm_extraMenu">
                        {items.slice(2, items.length).map(({ label, icon, url, className }, i) => (
                            <a href={url} key={i} className={`mm_menuItem ${className}`}>
                                <i className={`pi ${icon} text-xl mr-2`}></i>
                                {label}
                            </a>
                        ))}
                    </div>
                </RenderIf>
            </RenderIf>

            <RenderIf isTrue={!collapsed}>
                {items.map(({ label, icon, url, className }, i) => (
                    <a href={url} key={i} className={`mm_menuItem ${className}`}>
                        <i className={`pi ${icon} text-xl mr-2`}></i>
                        {label}
                    </a>
                ))}
            </RenderIf>
        </div>
    );
}

export default Menu;
