import { Location } from '../features/onfleet/onfleetSlice';

/* eslint-disable no-unused-vars */
export const DateFormat = 'DD/MM/YYYY';
export const DateTimeFormat = 'DD/MM/YYYY HH:mm:ss';

export const backendDEV = 'http://localhost:8001/operations-aduana/aduana-api/';
export const backendPROD = 'https://api.mundimoto.com/operations-aduana/aduana-api/';

export const operationsDEV = 'http://localhost:8000/operations-api/';
export const operationsPROD = 'https://operations.mundimoto.com/operations-api/';

export const motorbikeApiDEV = 'https://services.piston.one/motorbike-api/';
export const motorbikeApiPROD = 'https://services.piston.one/motorbike-api/';

export interface ResponseErrorData {
    status: number;
    name: string;
    message: string;
    form_errors?: string;
}

export interface ResponseError {
    response: {
        data: ResponseErrorData;
    };
}

export interface FormErrors {
    [key: string]: {
        message: string;
        code: string;
    }[];
}

export interface Rejected extends Omit<ResponseErrorData, 'form_errors'> {
    form_errors?: FormErrors;
}

export type ComponentLifecycleValues = 'loading' | 'succeeded' | 'failed' | 'idle' | Rejected;

// eslint-disable-next-line no-shadow
export enum ComponentLifecycle {
    LOADING = 'loading',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed',
    IDLE = 'idle',
}

export interface Zone {
    code: string;
    zone: string;
    floor: string;
}

export interface Subzone {
    warehouse: string;
    floor: string;
    zone: string;
    group: string;
    code: string;
    parcel: string | null;
}

export const Aduanas: Location[] = [
    { label: 'warehouse.aduanas.barcelona_binefar_general', value: 'barcelona_binefar_general' },
    { label: 'warehouse.aduanas.barcelona_binefar_warranty', value: 'barcelona_binefar_warranty' },
    { label: 'warehouse.aduanas.barcelona_binefar_reception', value: 'barcelona_binefar_reception' },
    { label: 'warehouse.aduanas.barcelona_oliva_general', value: 'barcelona_oliva_general' },
    { label: 'warehouse.aduanas.madrid_limite_general', value: 'madrid_limite_general' },
    { label: 'warehouse.aduanas.milan_strada_general', value: 'milan_strada_general' },
    { label: 'warehouse.aduanas.malaga_corrochano_general', value: 'malaga_corrochano_general' },
    { label: 'warehouse.aduanas.malaga_tajamar_general', value: 'malaga_tajamar_general' },
];

export enum RoutePaths {
    TOKEN = '/token',
    LOGOUT = '/logout',
    HOME = '/',
    ONFLEET = '/onfleet',
    DEFLEET = '/defleet',
    QR = '/qr',
    NEW_MOTO = '/newMoto',
    ZONE_QR = '/zoneQr',
    POSITION = '/position',
}
