import axios from './../app/axiosInterceptor';

export function listOnfleetAPI() {
    return axios({
        method: 'get',
        url: `onfleet`,
    });
}

export function listDefleetAPI() {
    return axios({
        method: 'get',
        url: `defleet`,
    });
}

export function getCheckinList(motorbike_id: string) {
    return axios({
        method: 'get',
        url: `onfleet/${motorbike_id}/checkin-list`,
    });
}

export function updateCheckinList(motorbike_id: string, checkinOptions: {}) {
    return axios({
        method: 'put',
        url: `onfleet/${motorbike_id}/checkin-list`,
        data: checkinOptions,
    });
}
