import axios from 'axios';
import { RedirectLogInPage } from '../features/tokens/RemoveToken';
import { backendDEV, backendPROD } from '../shared/constants';

// axios instance for making requests
const axiosInstance = axios.create({
    baseURL: process.env.NODE_ENV !== 'production' ? backendDEV : backendPROD,
});

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
    // add token to request headers
    const token = localStorage.getItem('token');
    if (token != null) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// response interceptor for adding token
axiosInstance.interceptors.response.use(
    (success) => success,
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
            RedirectLogInPage();
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
