import { useTranslation } from 'react-i18next';
import React from 'react';
import { Zone } from '../../constants';

export default function ZoneElem({ zone }: { zone: Zone }) {
    const { t } = useTranslation();

    return (
        <div className="w-full flex align-items-center">
            {zone.code && <span className="font-semibold w-2rem mr-2">{zone.code}</span>}
            <span className="flex-grow-1 opacity-80 text-sm">{t(`warehouse.zones.${zone.zone}`)}</span>
            <span className="ml-2 opacity-40">P {t(`warehouse.floors.${zone.floor}`)}</span>
        </div>
    );
}
