import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { listDefleetAPI } from '../aduanaAPI';
import { Aduanas, ComponentLifecycle, Rejected, ResponseErrorData } from '../../shared/constants';
import { Location } from '../onfleet/onfleetSlice';

export interface Defleet {
    license_plate: string;
    brand: string;
    model: string;
    brandModel: string;
    location: string;
    updated_at: string;
}

export interface DefleetState {
    defleets: Defleet[];
    locations: Location[];
    status: string;
    error: string;
}

const initialState: DefleetState = {
    defleets: [],
    locations: Aduanas,
    status: ComponentLifecycle.IDLE,
    error: '',
};

export const fetchDefleets = createAsyncThunk<Defleet[]>('defleet/fetchDefleets', async (_, { rejectWithValue }) => {
    try {
        const response = await listDefleetAPI();

        return response.data.map((defleet: Defleet) => ({
            ...defleet,
            brandModel: `${defleet.brand} ${defleet.model}`,
        }));
    } catch (responseError: any) {
        const { name, message } = responseError.response.data as ResponseErrorData;
        return rejectWithValue({
            name,
            message,
        } as Rejected);
    }
});

export const defleetSlice = createSlice({
    name: 'defleetsState',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchDefleets.pending, (state, action) => {
                state.status = ComponentLifecycle.LOADING;
                state.error = '';
            })
            .addCase(fetchDefleets.fulfilled, (state, action) => {
                state.status = ComponentLifecycle.SUCCEEDED;
                state.defleets = state.defleets.concat(action.payload);
            })
            .addCase(fetchDefleets.rejected, (state, action) => {
                state.status = ComponentLifecycle.FAILED;
                state.error = action.error?.message ?? '';
            });
    },
});

export default defleetSlice.reducer;
