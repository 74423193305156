import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ComponentLifecycle, Rejected, ResponseErrorData } from '../../shared/constants';
import {
    addToFreeFlow,
    getMotorbikeInfo,
    idFromFrameNumber,
    idFromLicensePlate,
    movementCheckout,
} from '../operationsAPI';
import { toast } from 'react-toastify';
import { getI18n } from 'react-i18next';

export interface MotoInfo {
    motorbike_id: string;
    frame_number: string;
    license_plate?: string;
    brand: string;
    model: string;
    kms: number;
    year: number;
    model_id: string;
}

export interface FreeFlowPayload {
    frame_number: string;
    license_plate?: string;
    brand: string;
    model: string;
    kms: number;
    year: number;
    status: string;
    flow: string;
}

export interface Movement {
    warehouse: string;
    floor: string;
    zone: string;
    parcel: string | null;
}

export interface QrState {
    motos: MotoInfo[];
    status: string;
    error: string;
    httpStatus?: number;
}

const initialState: QrState = {
    motos: [],
    status: ComponentLifecycle.IDLE,
    error: '',
    httpStatus: 0,
};

const mapAPIInfo = (data: any, motorbike_id: string) => {
    const response: MotoInfo = {
        motorbike_id,
        frame_number: data.frame_number,
        license_plate: data?.license_plate,
        brand: data.brand,
        model: data.model,
        kms: data.kms,
        year: data.year,
        model_id: data.model_id,
    };
    return response;
};

const NOT_FOUND_ERROR = { status: 204, code: 204, message: 'Not found' };
const notFoundError = (data: { status: number }) => data.status === 204;

export const getMotoFromLicensePlate = createAsyncThunk(
    'qr/getMotoFromLicensePlate',
    async (licensePlate: string, { rejectWithValue }) => {
        try {
            const response = await idFromLicensePlate(licensePlate);
            if (notFoundError(response)) return rejectWithValue(NOT_FOUND_ERROR);

            const motoInfo = await getMotorbikeInfo(response.data.motorbike_id);
            if (notFoundError(motoInfo)) return rejectWithValue(NOT_FOUND_ERROR);

            return mapAPIInfo(motoInfo.data, response.data.motorbike_id);
        } catch (responseError: any) {
            const { name, message, status } = Object.assign(responseError.response.data, {
                status: responseError.response.status,
            }) as ResponseErrorData;
            return rejectWithValue({
                name,
                message,
                status,
            } as Rejected);
        }
    }
);

export const addMotoToFreeFlow = createAsyncThunk(
    'qr/addMotoToFreeFlow',
    async (motoInfo: MotoInfo, { rejectWithValue }) => {
        try {
            const { motorbike_id, ...motoInfoWithoutId } = motoInfo;
            const motoFreeFlowInfo: FreeFlowPayload = { ...motoInfoWithoutId, status: 'warehouse', flow: 'free_flow' }; // TODO harcoded warehouse
            await addToFreeFlow(motoFreeFlowInfo);
            const response = await idFromFrameNumber(motoInfo.frame_number);
            if (notFoundError(response)) return rejectWithValue(NOT_FOUND_ERROR);

            return { ...motoInfo, motorbike_id: response.data.motorbike_id };
        } catch (responseError: any) {
            const { name, message, status } = Object.assign(responseError.response.data, {
                status: responseError.response.status,
            }) as ResponseErrorData;
            return rejectWithValue({
                name,
                message,
                status,
            } as Rejected);
        }
    }
);

export const setPosition = createAsyncThunk(
    'qr/setPosition',
    async (payload: { motorbike_id: string; movement: Movement }, { rejectWithValue }) => {
        try {
            const { motorbike_id, movement } = payload;
            await movementCheckout(motorbike_id, movement);
            return payload;
        } catch (responseError: any) {
            const { name, message, status } = Object.assign(responseError.response.data, {
                status: responseError.response.status,
            }) as ResponseErrorData;
            return rejectWithValue({
                name,
                message,
                status,
            } as Rejected);
        }
    }
);

export const qrSlice = createSlice({
    name: 'qrState',
    initialState,
    reducers: {
        clearQrState: (state) => {
            state.motos = [];
        },
        clearQrStatus: (state) => {
            state.status = ComponentLifecycle.IDLE;
            state.error = '';
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(getMotoFromLicensePlate.pending, (state) => {
                state.status = ComponentLifecycle.LOADING;
                state.error = '';
            })
            .addCase(getMotoFromLicensePlate.fulfilled, (state, action) => {
                state.motos = [...state.motos, action.payload];
                state.status = ComponentLifecycle.IDLE;
            })
            .addCase(getMotoFromLicensePlate.rejected, (state, action) => {
                state.status = ComponentLifecycle.FAILED;
                const payload = action.payload as Rejected;
                state.error = 'status' in payload ? 'getMotoFromLicensePlateError' : 'generic';
            })
            .addCase(addMotoToFreeFlow.pending, (state) => {
                state.status = ComponentLifecycle.LOADING;
                state.error = '';
                state.httpStatus = 0;
            })
            .addCase(addMotoToFreeFlow.fulfilled, (state, action) => {
                state.motos = [...state.motos, action.payload];
                state.status = ComponentLifecycle.SUCCEEDED;
                state.error = '';
                state.httpStatus = 200;
            })
            .addCase(addMotoToFreeFlow.rejected, (state, action) => {
                state.status = ComponentLifecycle.FAILED;
                state.error = 'addMotoToFreeFlowError';
                const payload = action.payload as Rejected;
                state.httpStatus = payload.status;
            })
            .addCase(setPosition.pending, (state) => {
                state.status = ComponentLifecycle.LOADING;
                state.error = '';
            })
            .addCase(setPosition.fulfilled, (state, action) => {
                state.status = ComponentLifecycle.IDLE;
                const message = getI18n().t('setPosition.success', {
                    position: getI18n().t(`warehouse.subzones.${action.payload?.movement?.zone}`),
                });
                toast.success(message, { autoClose: 2000 });
            })
            .addCase(setPosition.rejected, (state, action) => {
                state.status = ComponentLifecycle.FAILED;
                state.error = 'setPositionError';
                const message = getI18n().t('error.moveMotorbike');
                toast.error(message, { autoClose: 2000 });
            });
    },
});

export default qrSlice.reducer;
