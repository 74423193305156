import { jsPDF } from 'jspdf';
import { PDFDocument } from 'pdf-lib';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ComponentLifecycle, Subzone } from '../../shared/constants';
import { addMotoToFreeFlow, getMotoFromLicensePlate, MotoInfo, qrSlice, setPosition } from './qrSlice';
import Qr from './Qr';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import moment from 'moment';
import useGetData from '../../shared/hooks/useGetData';
import ZoneSelector from '../../shared/components/zoneselector/ZoneSelector';
import MM_Button, { ButtonStyle } from '../../shared/button/Button';

interface DropdownElement {
    label: string;
    code: string;
}

interface NewMotoInfo {
    frame_number: string | null;
    license_plate: string | null;
    brand: string | null;
    model: string | null;
    kms: number | null;
    year: number | null;
}

const emptyNewMoto = {
    license_plate: null,
    frame_number: null,
    brand: null,
    model: null,
    kms: null,
    year: null,
};

export default function QrGenerator() {
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    useGetData();

    const { motos, status, error, httpStatus } = useAppSelector((state) => state.qrState);
    const { clearQrStatus, clearQrState } = qrSlice.actions;
    const { warehouse } = useAppSelector((state) => state.data);
    const [subzone, setSubzone] = useState<Subzone>();

    const [license, setLicense] = useState('');
    const [downloading, setDownloading] = useState(false);
    const [currentQR, setCurrentQR] = useState<MotoInfo | null>(null);
    const [newMoto, setNewMoto] = useState<NewMotoInfo>(emptyNewMoto);
    const [showDialog, setShowDialog] = useState(false);

    const onEnterLicense = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(getMotoFromLicensePlate(license));
    };

    useEffect(() => {
        if (status === ComponentLifecycle.IDLE) setLicense('');
        else if (status === ComponentLifecycle.FAILED && error === 'getMotoFromLicensePlateError') {
            setShowDialog(true);
            setNewMoto({ ...emptyNewMoto, license_plate: license });
            dispatch(clearQrStatus());
        }
    }, [status]);

    const currentDownloadedMoto = useRef(0);
    const pdfPages = useRef<{ [key: string]: ArrayBuffer }>({});

    const mergePagesAndDownload = async (pdfsToMerge: ArrayBuffer[]) => {
        const mergedPdf = await PDFDocument.create();
        const actions = pdfsToMerge.map(async (pdfBuffer) => {
            const pdf = await PDFDocument.load(pdfBuffer);
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
            copiedPages.forEach((page) => mergedPdf.addPage(page));
        });
        await Promise.all(actions);
        const mergedPdfFile = await mergedPdf.save();

        const link = document.createElement('a');
        const date = moment(new Date()).format('YYYY-MM-DD_HH-mm-ss');
        link.download = `${date}_${subzone ? subzone.zone : ''}_QRs.pdf`;
        let binaryData = [];
        binaryData.push(mergedPdfFile);
        link.href = URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
        link.dispatchEvent(new MouseEvent('click'));

        return mergedPdfFile;
    };

    async function createPdfPages() {
        const qrDOM = document.getElementById('qr-gen') as HTMLElement;
        const doc = new jsPDF();
        const pdfWidth = doc.internal.pageSize.getWidth();

        const currentNumber = currentDownloadedMoto.current;
        await doc.html(qrDOM, {
            callback: (doc) => (pdfPages.current[currentNumber] = doc.output('arraybuffer')),
            x: 0,
            y: 0,
            width: pdfWidth,
            windowWidth: qrDOM.getBoundingClientRect().width,
        });

        currentDownloadedMoto.current += 1;
        if (currentDownloadedMoto.current < motos.length) setCurrentQR(motos[currentDownloadedMoto.current]);
        else {
            await mergePagesAndDownload(Object.keys(pdfPages.current).map((key) => pdfPages.current[key]));
            pdfPages.current = {};
            setDownloading(false);
            setSubzone(undefined);
            setCurrentQR(null);
            dispatch(clearQrState());
        }
    }

    const onDownload = async () => {
        if (!subzone) return;

        setDownloading(true);
        currentDownloadedMoto.current = 0;

        motos.forEach((moto) => {
            dispatch(
                setPosition({
                    motorbike_id: moto.motorbike_id,
                    movement: {
                        warehouse: subzone.warehouse,
                        floor: subzone.floor,
                        zone: subzone.zone,
                        parcel: subzone.parcel,
                    },
                })
            );
        });

        motos.length > 0 && setCurrentQR(motos[currentDownloadedMoto.current]);
    };

    useEffect(() => {
        if (currentQR) createPdfPages();
    }, [currentQR]);

    const onCloseDialog = () => {
        setNewMoto(emptyNewMoto);
        setShowDialog(false);
        dispatch(clearQrStatus());
        setLicense('');
    };

    const onDialogEnter = async () => {
        const newFreeFlowMoto = newMoto as MotoInfo;
        if (!newFreeFlowMoto.license_plate) delete newFreeFlowMoto.license_plate;

        const response = await dispatch(addMotoToFreeFlow(newMoto as MotoInfo));
        if (!('error' in response)) onCloseDialog();
    };

    return (
        <div className="h-full flex flex-column align-items-center justify-content-center">
            <h1 className="mm_mainTitle w-full max-w-23rem">{t('menu.qrGenerator')}</h1>

            <ZoneSelector
                subZones={warehouse.zones}
                onChange={(subzone) => setSubzone(subzone)}
                title={t('qrGenerator.motosPosition')}
            />

            <form
                onSubmit={onEnterLicense}
                className="w-full max-w-23rem my-3 flex align-items-center justify-content-center"
            >
                <InputText
                    className={`flex-grow-1 mr-2 ${license ? 'uppercase' : ''}`}
                    id={'licenseInput'}
                    value={license}
                    placeholder={t('qrGenerator.inputPlaceholder')}
                    onChange={(e) => setLicense(e.target.value.toUpperCase())}
                />

                <MM_Button
                    label=""
                    className="w-min"
                    icon="pi pi-check"
                    disabled={downloading || license.length <= 0}
                    loading={status === ComponentLifecycle.LOADING}
                />
            </form>

            {error && error === 'generic' && (
                <div className="w-full flex align-items-center justify-content-center">
                    <i className="pi pi-times-circle text-red-600 mr-2"></i>
                    <p className="text-red-600">{t('error.getMotorbike')}</p>
                </div>
            )}

            <div className="flex-grow-1 overflow-y-auto w-full flex flex-column align-items-center justify-content-start">
                {motos.map(({ license_plate, brand, model }, i) => (
                    <div key={i} className="flex mb-2">
                        <span className="text-lg font-semibold mr-2">{license_plate}</span>
                        <span className="text-lg mr-2">{brand}</span>
                        <span className="text-lg mr-2">{model}</span>
                    </div>
                ))}
            </div>

            <MM_Button
                label={t('qrGenerator.downloadButton')}
                icon="pi pi-qrcode"
                className="mt-3 w-full max-w-23rem"
                onClick={onDownload}
                disabled={motos.length <= 0 || !subzone}
                loading={downloading}
            />

            <Qr currentQR={currentQR} />

            <Dialog
                modal
                className="w-30rem"
                visible={showDialog}
                header={t('qrGenerator.dialogHeader')}
                footer={
                    <div className="w-full flex align-items-center justify-content-end pt-3">
                        <MM_Button
                            style={ButtonStyle.TEXT}
                            className="w-min px-4"
                            label={t('dialog.cancel')}
                            onClick={onCloseDialog}
                            disabled={status === ComponentLifecycle.LOADING}
                        />

                        <MM_Button
                            label={t('dialog.save')}
                            className="w-min px-4"
                            icon="pi pi-check"
                            disabled={
                                !newMoto.frame_number ||
                                !newMoto.brand ||
                                !newMoto.model ||
                                !newMoto.kms ||
                                !newMoto.year
                            }
                            loading={status === ComponentLifecycle.LOADING}
                            onClick={onDialogEnter}
                        />
                    </div>
                }
                onHide={() => setShowDialog(false)}
                draggable={false}
                closable={false}
            >
                <div className="flex flex-column justify-content-center">
                    <label className="mb-1">{t('dataTable.licensePlate')}</label>
                    <InputText
                        className="uppercase mb-3"
                        value={newMoto?.license_plate || ''}
                        onChange={(e) => setNewMoto({ ...newMoto, license_plate: e.target.value.toUpperCase() })}
                    />

                    <label className="mb-1">{t('dataTable.frameNumber')}</label>
                    <InputText
                        className="uppercase mb-3"
                        value={newMoto?.frame_number || ''}
                        onChange={(e) => setNewMoto({ ...newMoto, frame_number: e.target.value.toUpperCase() })}
                    />

                    <label className="mb-1">{t('dataTable.brand')}</label>
                    <InputText
                        className="mb-3"
                        value={newMoto?.brand || ''}
                        onChange={(e) => setNewMoto({ ...newMoto, brand: e.target.value })}
                    />

                    <label className="mb-1">{t('dataTable.model')}</label>
                    <InputText
                        className="mb-3"
                        value={newMoto?.model || ''}
                        onChange={(e) => setNewMoto({ ...newMoto, model: e.target.value })}
                    />

                    <label className="mb-1">{t('dataTable.km')}</label>
                    <InputNumber
                        className="mb-3"
                        locale={i18n.language}
                        value={newMoto?.kms || null}
                        onChange={(e) => setNewMoto({ ...newMoto, kms: e.value })}
                    />

                    <label className="mb-1">{t('dataTable.year')}</label>
                    <InputNumber
                        className="mb-3"
                        locale={i18n.language}
                        useGrouping={false}
                        value={newMoto?.year || null}
                        onChange={(e) => setNewMoto({ ...newMoto, year: e.value })}
                    />

                    <div
                        className={`w-full flex align-items-center justify-content-center ${
                            status === ComponentLifecycle.FAILED && error === 'addMotoToFreeFlowError'
                                ? ''
                                : 'opacity-0'
                        }`}
                    >
                        <i className="pi pi-times-circle text-red-600 mr-2"></i>
                        <p className="text-red-600">
                            {httpStatus === 409 ? t('qrGenerator.error.exists') : t('qrGenerator.error.default')}
                        </p>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
