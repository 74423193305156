import React, {
    forwardRef,
    HTMLAttributes,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { OnResultFunction, QrReader } from 'react-qr-reader';
import styles from './QrScanner.module.scss';
import { RenderIf } from '../../shared/components/RenderIf';

const INACTIVE_TIME_IN_SECONDS = 30;

type Props = {
    handleQRResult: (data: string) => Promise<boolean>;
    header?: JSX.Element;
    footer?: JSX.Element;
} & HTMLAttributes<HTMLDivElement>;

export type CanResetLastScan = { resetLastScan(): void };

const QrScanner = forwardRef<CanResetLastScan, Props>(({ handleQRResult, header, footer, ...restProps }, ref) => {
    const scanning = useRef<boolean>(false);
    const lastScan = useRef<string | null>(null);

    const [active, setActive] = useState(true);
    const timer = useRef<NodeJS.Timeout | null>(null);

    const startInactiveTimer = useCallback(() => {
        setActive(true);
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => setActive(false), INACTIVE_TIME_IN_SECONDS * 1000);
    }, []);

    useEffect(() => {
        startInactiveTimer();

        return () => {
            if (timer.current) clearTimeout(timer.current);
        };
    }, [startInactiveTimer]);

    useImperativeHandle(ref, () => ({
        resetLastScan() {
            lastScan.current = null;
        },
    }));

    const handleResult: OnResultFunction = async (result) => {
        if (scanning.current || !result) return;
        const resultText = result.getText();

        if (resultText == lastScan.current) return;
        scanning.current = true;
        startInactiveTimer();

        lastScan.current = resultText;
        await handleQRResult(resultText);
        scanning.current = false;
    };

    return (
        <div
            data-testid="qrscanner"
            className={`${styles.video} w-full relative flex align-items-center`}
            style={{ backgroundColor: '#232F34' }}
            {...restProps}
        >
            <RenderIf isTrue={active}>
                <QrReader
                    onResult={handleResult}
                    constraints={{ facingMode: 'environment' }}
                    className={`${styles.qrReader}`}
                    videoContainerStyle={{ height: '100%' }}
                    videoStyle={{ objectFit: 'cover', height: '100%' }}
                    scanDelay={100}
                />
            </RenderIf>

            <RenderIf isTrue={!active}>
                <div
                    data-testid="pausedcamera"
                    className="absolute w-full h-full flex flex-column align-items-center justify-content-center z-4 cursor-pointer"
                    onClick={startInactiveTimer}
                    data-cy="qrScanner-refresh-button"
                >
                    <i className={`pi pi-refresh text-2xl mr-2 text-white mb-3`}></i>
                    <p className="text-white">click to activate the camera</p>
                </div>
            </RenderIf>

            <div className={`absolute top-0 left-0 bottom-0 right-0 ${styles.grid}`}>
                <div className={`${styles.side} ${styles.top}`}></div>
                <div className={`${styles.side} ${styles.bottom}`}></div>
                <div className={`${styles.side} ${styles.left}`}></div>
                <div className={`${styles.side} ${styles.right}`}></div>

                <div className={`w-full h-full ${styles.center}`}>
                    <div className={`${styles.corner} ${styles.topLeftCorner}`}></div>
                    <div className={`${styles.corner} ${styles.topRightCorner}`}></div>
                    <div className={`${styles.corner} ${styles.bottomLeftCorner}`}></div>
                    <div className={`${styles.corner} ${styles.bottomRightCorner}`}></div>
                </div>
            </div>

            {header}
            {footer}
        </div>
    );
});

QrScanner.displayName = 'QrScanner';
export default QrScanner;
