import axios from './../app/axiosInterceptorOperations';
import { FreeFlowPayload, Movement } from './qrGenerator/qrSlice';

export function addToFreeFlow(freeFlowPayload: FreeFlowPayload) {
    return axios({
        method: 'post',
        url: 'flow/free-flow',
        data: freeFlowPayload,
    });
}

export function idFromLicensePlate(licensePlate: string) {
    return axios({
        method: 'get',
        url: `motorbike/id-by-license-plate/${licensePlate}`,
    });
}

export function idFromFrameNumber(frameNumber: string) {
    return axios({
        method: 'get',
        url: `motorbike/id-by-frame-number/${frameNumber}`,
    });
}

export function getMotorbikeInfo(motorbikeId: string) {
    return axios({
        method: 'get',
        url: `motorbike/${motorbikeId}`,
    });
}

export function movementCheckout(motorbikeId: string, movement: Movement) {
    return axios({
        method: 'post',
        url: `move/${motorbikeId}`,
        data: movement,
    });
}

export function getWarehouseList() {
    return axios({
        method: 'get',
        url: 'warehouse-zones',
    });
}
