import React from 'react';
import { useTranslation } from 'react-i18next';
import { Subzone } from '../../constants';

export default function SubzoneElem({ subzone }: { subzone: Subzone }) {
    const { t } = useTranslation();

    return (
        <div className="w-full flex align-items-center">
            {subzone.code && <span className="font-semibold w-2rem mr-2">{subzone.code}</span>}
            <span className="flex-grow-1 opacity-80 text-sm">{t(`warehouse.subzones.${subzone.zone}`)}</span>
            <span className="ml-2 opacity-40">P {t(`warehouse.floors.${subzone.floor}`)}</span>
        </div>
    );
}
