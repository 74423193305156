import React from 'react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { Subzone } from '../../shared/constants';

interface Props {
    currentQR: Subzone | null;
}

export default function ZoneQr({ currentQR }: Props) {
    const { t } = useTranslation();

    if (!currentQR) return null;

    const { warehouse, floor, zone, code, parcel } = currentQR;
    const QRData = { warehouse, floor, zone, parcel };

    return (
        currentQR && (
            <div
                id="qr-gen"
                className={`relative bg-white flex flex-column align-items-center justify-content-center w-min p-3 ${
                    currentQR.zone ? '' : 'opacity-0'
                }`}
            >
                {QRData && <QRCode value={JSON.stringify(QRData)} size={450} level={'H'} includeMargin={true} />}

                {code && <p className="m-0 font-bold text-center text-5xl">{code}</p>}
                {!code && <p className="m-0 font-bold text-center text-5xl">{zone}</p>}

                {zone && <p className="m-0 text-center text-4xl">{t(`warehouse.subzones.${zone}`)}</p>}

                <div className="flex align-items-center justify-content-center mt-2">
                    {floor && (
                        <>
                            <p className="m-0 text-center text-1xl capitalize text-400 pr-2">
                                {t('qrGenerator.floor')}
                            </p>
                            <p className="m-0 text-center text-1xl text-400 pr-4">{t(`warehouse.floors.${floor}`)}</p>
                        </>
                    )}
                    {warehouse && (
                        <p className="m-0 text-center text-1xl text-400">{t(`warehouse.warehouses.${warehouse}`)}</p>
                    )}
                    {parcel && (
                        <p className="m-0 text-center text-1xl text-400 pl-4">{`${t('qrGenerator.parcel')} ${parcel}`}</p>
                    )}
                </div>
            </div>
        )
    );
}
