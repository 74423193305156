import { jsPDF } from 'jspdf';
import { PDFDocument } from 'pdf-lib';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ComponentLifecycle, Subzone } from '../../shared/constants';
import { useTranslation } from 'react-i18next';
import useGetData from '../../shared/hooks/useGetData';
import ZoneQr from './ZoneQr';
import MM_Button from '../../shared/button/Button';

export default function ZoneQRGenerator() {
    const { t } = useTranslation();
    useGetData();

    const { warehouse } = useAppSelector((state) => state.data);

    const [downloading, setDownloading] = useState(false);
    const [currentQR, setCurrentQR] = useState<Subzone | null>(null);

    const currentZone = useRef(0);
    const pdfPages = useRef<{ [key: string]: ArrayBuffer }>({});

    const mergePagesAndDownload = async (pdfsToMerge: ArrayBuffer[]) => {
        const mergedPdf = await PDFDocument.create();
        const actions = pdfsToMerge.map(async (pdfBuffer) => {
            const pdf = await PDFDocument.load(pdfBuffer);
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
            copiedPages.forEach((page) => mergedPdf.addPage(page));
        });
        await Promise.all(actions);
        const mergedPdfFile = await mergedPdf.save();

        const link = document.createElement('a');
        link.download = `zone_QRs.pdf`;
        let binaryData = [];
        binaryData.push(mergedPdfFile);
        link.href = URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
        link.dispatchEvent(new MouseEvent('click'));

        return mergedPdfFile;
    };

    async function createPdfPages() {
        const qrDOM = document.getElementById('qr-gen') as HTMLElement;
        const doc = new jsPDF('p', 'mm', [420, 297]);
        const pdfWidth = doc.internal.pageSize.getWidth();

        const currentNumber = currentZone.current;
        await doc.html(qrDOM, {
            callback: (doc) => (pdfPages.current[currentNumber] = doc.output('arraybuffer')),
            x: 0,
            y: 0,
            width: pdfWidth,
            windowWidth: qrDOM.getBoundingClientRect().width,
        });

        currentZone.current += 1;
        if (currentZone.current < warehouse.zones.length) setCurrentQR(warehouse.zones[currentZone.current]);
        else {
            await mergePagesAndDownload(Object.keys(pdfPages.current).map((key) => pdfPages.current[key]));
            pdfPages.current = {};
            setDownloading(false);
            setCurrentQR(null);
        }
    }

    const onDownload = async () => {
        setDownloading(true);
        currentZone.current = 0;
        warehouse.zones.length > 0 && setCurrentQR(warehouse.zones[currentZone.current]);
    };

    useEffect(() => {
        if (currentQR) createPdfPages();
    }, [currentQR]);

    return (
        <div className="h-100 flex flex-column align-items-center" style={{ height: '80vh' }}>
            <h1 className="mm_mainTitle w-full max-w-23rem">{t('menu.zoneQr')}</h1>
            <MM_Button
                label={t('zoneQrGenerator.downloadButton')}
                icon="pi pi-qrcode"
                className="my-3 w-full max-w-23rem"
                onClick={onDownload}
                disabled={!warehouse.zones || warehouse.status === ComponentLifecycle.FAILED}
                loading={downloading}
            />

            <ZoneQr currentQR={currentQR} />
        </div>
    );
}
